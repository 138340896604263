import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "exposed-events-from-enduser"
    }}>{`Exposed events from enduser`}</h2>
    <h3 {...{
      "id": "you-can-push-the-following-events-to-any-analytics-platform"
    }}>{`You can push the following events to any analytics platform`}</h3>
    <h4 {...{
      "id": "agentjoined"
    }}>{`agentJoined`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'agentJoined',
  eventLabel: 'session_id',
  room: 'room_id',
  name: 'Serving agents name',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when agent joins the chat`}</p>
    <h4 {...{
      "id": "autopopuptriggered"
    }}>{`autoPopUpTriggered`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'autoPopUpTriggered',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when auto popup of chat is triggered`}</p>
    <h4 {...{
      "id": "sessionrated"
    }}>{`sessionRated`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'sessionRated',
  eventLabel: 'session_id:agent_name',
  room: 'room_id',
  rating: 'given answers',
  agentName: 'Serving agent name',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when customer has rated the session`}</p>
    <h4 {...{
      "id": "contactformsent"
    }}>{`contactFormSent`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'contactFormSent',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when contact form is sent`}</p>
    <h4 {...{
      "id": "cookiesaccepted"
    }}>{`cookiesAccepted`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'cookiesAccepted',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when user clicks accept cookies`}</p>
    <h4 {...{
      "id": "cookiesdeclined"
    }}>{`cookiesDeclined`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'cookiesDeclined',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when user clicks decline cookies`}</p>
    <h4 {...{
      "id": "tabclosed"
    }}>{`tabClosed`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'tabClosed',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when chat is closed`}</p>
    <h4 {...{
      "id": "tabclicked"
    }}>{`tabClicked`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'tabClicked',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when chat tab is pushed`}</p>
    <h4 {...{
      "id": "videocall"
    }}>{`videoCall`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'videoCall',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when videocall is started`}</p>
    <h4 {...{
      "id": "cobstarted"
    }}>{`cobStarted`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'cobStarted',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when cobrowsing is started`}</p>
    <h4 {...{
      "id": "assistantloaded"
    }}>{`assistantLoaded`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'assistantLoaded',
  eventLabel: 'session_id',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when assistant is loaded`}</p>
    <h4 {...{
      "id": "assistantclick"
    }}>{`assistantClick`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'assistantClick',
  eventLabel: 'session_id:chatbot_action_type:action_text',
  room: 'room_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires on assistant interaction`}</p>
    <h4 {...{
      "id": "smileeinitiated"
    }}>{`smileeInitiated`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  eventCategory: 'smileechat',
  chatAction: 'smileeInitiated',
  eventLabel: 'session_id',
  pageUrl: 'https://smilee.dev'
}
`}</code></pre>
    <p>{`Fires when Smilee script is succesfully initiated`}</p>
    <h3 {...{
      "id": "add-events-to-script"
    }}>{`Add events to script`}</h3>
    <h4 {...{
      "id": "following-example-pushes-events-to-gtm-datalayer"
    }}>{`Following example pushes events to GTM dataLayer`}</h4>
    <p>{`First step is to add `}<inlineCode parentName="p">{`eventReceiver`}</inlineCode>{` to script running the chat`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`eventReceiver: function(props) {
  if (typeof window.dataLayer === 'object') {
    dataLayer.push(Object.assign({}, {'event': 'ChatEvent'}, props));
  }
}
`}</code></pre>
    <p>{`Full example of script below`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8" async>
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          eventReceiver: function(props) {
            if (typeof window.dataLayer === 'object') {
              dataLayer.push(Object.assign({}, {'event': 'ChatEvent'}, props));
            }
          }
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>
    <h4 {...{
      "id": "following-script-will-push-the-events-to-google-analytics"
    }}>{`Following script will push the events to Google Analytics`}</h4>
    <p>{`Events can be pushed to Google Analytics also using the ga('send') action.
`}<a parentName="p" {...{
        "href": "https://developers.google.com/analytics/devguides/collection/analyticsjs/events"
      }}>{`https://developers.google.com/analytics/devguides/collection/analyticsjs/events`}</a></p>
    <p>{`Its good to check that the ga function is available for eventReceiver to prevent it crashing the chat in cases
where google analytics cookies are not enabled`}</p>
    <p>{`First step is to add `}<inlineCode parentName="p">{`eventReceiver`}</inlineCode>{` to script running the chat`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`eventReceiver: function(props) {
  if (typeof window.ga === 'function') {
    ga('send', 'event', props.eventCategory, props.chatAction, props.eventLabel)
  }
}
`}</code></pre>
    <p>{`Full example of script below`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8" async>
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          eventReceiver: function(props) {
            if (typeof window.ga === 'function') {
              ga('send', 'event', props.eventCategory, props.chatAction, props.eventLabel);
            }
          }
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      